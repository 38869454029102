import { useCallback, useEffect, useState, useMemo } from "react";
import Onboard from "bnc-onboard";
import Web3 from "web3";
import CONST from '../../const';

const useConnectWallet = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [onboard, setOnboard] = useState(null);
  const [address, setAddress] = useState("");
  const [wallet, setWallet] = useState(null);
  const [isInitOnboard, setIsInitOnboard] = useState(false);
  const [isWalletChecked, setIsWalletChecked] = useState(false);

  const onBoard = useCallback(
    async (walletName) => {
      setIsWalletChecked(false);
      try {
        await onboard.walletSelect(walletName);
      } catch (e) {
        console.error("walletSelect error: ", e);
      }

      try {
        const result = await onboard.walletCheck();
        console.log("check result: ", result);

        setIsConnected(result);
      } catch (e) {
        setIsConnected(false);
        console.error("walletCheck error: ", e);
      }

      setIsWalletChecked(true);
    },
    [onboard]
  );

  useEffect(() => {
    const onboard = Onboard({
      networkId: CONST.NETWORK_ID,
      hideBranding: true,
      walletSelect: {
        wallets: [
          { walletName: "metamask" },
          {
            walletName: "walletConnect",
            infuraKey: "5b00229e10bd4ac6a0c7d225c55a2124",
          },
        ],
      },
      walletCheck: [
        { checkName: "connect" },
        { checkName: "network" },
        { checkName: "accounts" },
      ],
      subscriptions: {
        wallet: (wallet) => {
          console.log("wallet: ", wallet);
          setWallet(wallet);
        },
        address: (address) => {
          console.log("address: ", address);
          setAddress(Web3.utils.toChecksumAddress(address));
          if (!address) {
            setIsConnected(false);
            localStorage.removeItem("wallet");
          }
        },
        network: (networkId) => {
          console.log("networkId: ", networkId);
        },
      },
      darkMode: true,
    });

    setOnboard(onboard);
    setIsInitOnboard(true);
  }, []);

  useEffect(() => {
    if (isInitOnboard && onboard) {
      const walletName = localStorage.getItem("wallet");
      if (walletName && walletName !== "undefined" && walletName !== "null") {
        onBoard(walletName);
      }

      setIsInitOnboard(false);
    }
  }, [isInitOnboard, onboard, onBoard]);

  useEffect(() => {
    if (isWalletChecked) {
      if (wallet && isConnected) {
        localStorage.setItem("wallet", wallet.name);
      } else {
        localStorage.removeItem("wallet");
      }
    }
  }, [wallet, isConnected, isWalletChecked]);

  const result = useMemo(() => {
    return {
      isConnected,
      wallet,
      address,
    };
  }, [isConnected, wallet, address]);

  return [result, onBoard];
};

export default useConnectWallet;
