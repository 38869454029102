import style from "./index.module.scss";
import MetacraftLogo from "../../assets/img/metacraft-logo.png";
import MetacraftText from "../../assets/img/metacraft-text.png";

const Header = (props) => {
  return (
    <header className={style.row}>
      <aside className={style.left}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://metacraft.cc/"
        >
          <img className={style.logoImg} src={MetacraftLogo} alt="Metacraft" />
          <img className={style.textImg} src={MetacraftText} alt="Metacraft" />
        </a>
      </aside>
      {props.address ? (
        <aside className={style.right}>
          {`Connected Wallet: ${props.address.substring(
            0,
            5
          )}...${props.address.substring(props.address.length - 4)}`}
        </aside>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;
