import React from "react";
import style from "./index.module.scss";
import Loader from "../Loader";

const Button = (props) => {
  const {
    size = "medium",
    type = "primary",
    disabled = false,
    loading = false,
    className = "",
    textClassName = "",
    onClick = () => {},
  } = props;

  return (
    <div
      className={`${style.container} ${style[size]} ${
        type ? style[type] : null
      } ${className} ${disabled || loading ? style.disabled : null}`}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      {loading ? <Loader style={{ marginRight: 5 }} /> : null}
      <div className={`${style.text} ${textClassName}`}>{props.children}</div>
    </div>
  );
};

export default Button;
