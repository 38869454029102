import axios from "axios";
import CONST from '../const';

const instance = axios.create({
  baseURL: CONST.BASE_URL,
  timeout: 60000,
});

instance.interceptors.response.use(
  function (response) {
    console.log("response: ", response)
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
