import { useEffect, useState } from "react";
import style from "./index.module.scss";
import Box from "../Box";
import listItemImg from "../../assets/img/list-item.png";
import getStartImg from "../../assets/img/get-start.png";
import Button from "../Button";
import Notification from "../Notification";

const ApeBox = (props) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: 'Ape In',
      send_to: 'G-Q7XNLB2V8D'
    })
  }, [])

  return (
    <Box>
      <div className={style.title}>
        <a
          href="https://docs.metacraft.cc/ape-score-2021/what-is-ape-score"
          target="_blank" rel="noreferrer">
          2021 APE Score
        </a>
      </div>
      <div className={style.description}>
        <div className={style.listItem}>
          <div className={style.listItemWrapper}>
            <img src={listItemImg} alt="" />
          </div>
          What have you been through on-chain in past 2021?
        </div>
        <div className={style.listItem}>
          <div className={style.listItemWrapper}>
            <img src={listItemImg} alt="" />
          </div>
          If you were living in minecraft, what kind of creature would your
          transaction behavior look like?
        </div>
        <div className={style.listItem}>
          <div className={style.listItemWrapper}>
            <img src={listItemImg} alt="" />
          </div>
          Get your score and creature now!
        </div>
      </div>
      <div className={style.getStart}>
        <img src={getStartImg} alt="" />
      </div>

      <div className={style.apeInContainer}>
        <div className={style.guide}>
          If your score {">"} <span style={{ color: "#EA4E34" }}>700</span>
          <br />
          you can mint an {" "}
          <span style={{ color: "#2539F4" }}>NFT</span>
          <br />
          ( 10k limited )
        </div>

        {props.isConnected ? (
          <div className={style.buttonContainer}>
            <Button
              className={style.button}
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                props.onApe().catch((e) => {
                  console.error("Ape In Failed: ", e);
                  Notification.error({
                    message: "Ape In Failed",
                    description: e.message || "Unknown Error",
                  });
                  setIsLoading(false);

                  window.gtag("event", "click", {
                    type: "ape in",
                    status: "failed",
                  });
                });
              }}
            >
              Ape In
            </Button>
          </div>
        ) : (
          <div className={style.buttonContainer}>
            <Button
              className={style.button}
              loading={isConnecting}
              onClick={async () => {
                setIsConnecting(true);
                await props.connectWallet();
                setIsConnecting(false);
              }}
            >
              Connect Wallet
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};

export default ApeBox;
