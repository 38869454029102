const env = process.env.REACT_APP_ENV;
const CONST = {
  production: {
    NETWORK_ID: 1,
    CONTRACT_ADDRESS: "0x0b1d6565d88F9Bf6473e21c2AB58D28A495d7BB5",
    EXPLORER_TX_PREFIX: "https://etherscan.io/tx/",
    MAX_CLAIM_COUNT: 10000,
    BASE_URL: "https://api.metacraft.cc",
  },
  development: {
    NETWORK_ID: 4,
    CONTRACT_ADDRESS: "0xdCd8898fE0ac80F6e34FE7f0cccE0F2faa62C9d9",
    EXPLORER_TX_PREFIX: "https://rinkeby.etherscan.io/tx/",
    MAX_CLAIM_COUNT: 10000,
    BASE_URL: "https://api.test.metacraft.cc",
  },
};

console.log(CONST[env])

export default CONST[env];
