const creatureImgMap = {
  "gold/bee": () => {
    return import("../../assets/img/gold/bee.png").then(
      (image) => image.default
    );
  },
  "gold/cow": () => {
    return import("../../assets/img/gold/cow.png").then(
      (image) => image.default
    );
  },
  "gold/creeper": () => {
    return import("../../assets/img/gold/creeper.png").then(
      (image) => image.default
    );
  },
  "gold/dolphin": () => {
    return import("../../assets/img/gold/dolphin.png").then(
      (image) => image.default
    );
  },
  "gold/enderdragon": () => {
    return import("../../assets/img/gold/enderdragon.png").then(
      (image) => image.default
    );
  },
  "gold/enderman": () => {
    return import("../../assets/img/gold/enderman.png").then(
      (image) => image.default
    );
  },
  "gold/irongolem": () => {
    return import("../../assets/img/gold/irongolem.png").then(
      (image) => image.default
    );
  },
  "gold/pig": () => {
    return import("../../assets/img/gold/pig.png").then(
      (image) => image.default
    );
  },
  "gold/slime": () => {
    return import("../../assets/img/gold/slime.png").then(
      (image) => image.default
    );
  },
  "gold/turtle": () => {
    return import("../../assets/img/gold/turtle.png").then(
      (image) => image.default
    );
  },
  "gold/wither": () => {
    return import("../../assets/img/gold/wither.png").then(
      (image) => image.default
    );
  },
  "gold/axolotl": () => {
    return import("../../assets/img/gold/axolotl.png").then(
      (image) => image.default
    );
  },

  "silver/bee": () => {
    return import("../../assets/img/silver/bee.png").then(
      (image) => image.default
    );
  },
  "silver/cow": () => {
    return import("../../assets/img/silver/cow.png").then(
      (image) => image.default
    );
  },
  "silver/creeper": () => {
    return import("../../assets/img/silver/creeper.png").then(
      (image) => image.default
    );
  },
  "silver/dolphin": () => {
    return import("../../assets/img/silver/dolphin.png").then(
      (image) => image.default
    );
  },
  "silver/enderdragon": () => {
    return import("../../assets/img/silver/enderdragon.png").then(
      (image) => image.default
    );
  },
  "silver/enderman": () => {
    return import("../../assets/img/silver/enderman.png").then(
      (image) => image.default
    );
  },
  "silver/irongolem": () => {
    return import("../../assets/img/silver/irongolem.png").then(
      (image) => image.default
    );
  },
  "silver/pig": () => {
    return import("../../assets/img/silver/pig.png").then(
      (image) => image.default
    );
  },
  "silver/slime": () => {
    return import("../../assets/img/silver/slime.png").then(
      (image) => image.default
    );
  },
  "silver/turtle": () => {
    return import("../../assets/img/silver/turtle.png").then(
      (image) => image.default
    );
  },
  "silver/wither": () => {
    return import("../../assets/img/silver/wither.png").then(
      (image) => image.default
    );
  },
  "silver/axolotl": () => {
    return import("../../assets/img/silver/axolotl.png").then(
      (image) => image.default
    );
  },


  "bronze/bee": () => {
    return import("../../assets/img/bronze/bee.png").then(
      (image) => image.default
    );
  },
  "bronze/cow": () => {
    return import("../../assets/img/bronze/cow.png").then(
      (image) => image.default
    );
  },
  "bronze/creeper": () => {
    return import("../../assets/img/bronze/creeper.png").then(
      (image) => image.default
    );
  },
  "bronze/dolphin": () => {
    return import("../../assets/img/bronze/dolphin.png").then(
      (image) => image.default
    );
  },
  "bronze/enderdragon": () => {
    return import("../../assets/img/bronze/enderdragon.png").then(
      (image) => image.default
    );
  },
  "bronze/enderman": () => {
    return import("../../assets/img/bronze/enderman.png").then(
      (image) => image.default
    );
  },
  "bronze/irongolem": () => {
    return import("../../assets/img/bronze/irongolem.png").then(
      (image) => image.default
    );
  },
  "bronze/pig": () => {
    return import("../../assets/img/bronze/pig.png").then(
      (image) => image.default
    );
  },
  "bronze/slime": () => {
    return import("../../assets/img/bronze/slime.png").then(
      (image) => image.default
    );
  },
  "bronze/turtle": () => {
    return import("../../assets/img/bronze/turtle.png").then(
      (image) => image.default
    );
  },
  "bronze/wither": () => {
    return import("../../assets/img/bronze/wither.png").then(
      (image) => image.default
    );
  },
  "bronze/axolotl": () => {
    return import("../../assets/img/bronze/axolotl.png").then(
      (image) => image.default
    );
  },
};

export default creatureImgMap;
