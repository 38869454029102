import { useState } from "react";
import style from "./App.module.scss";
import { ScoreBox, ApeBox, Header } from "./components";
import axios from "./util/axios";
import useConnectWallet from "./common/hooks/useConnectWallet";
import Web3 from "web3";

function App() {
  const [{ isConnected, wallet, address }, connectWallet] = useConnectWallet();
  const [summary, setSummary] = useState(null);
  const [claimAddress, setClaimAddress] = useState("");

  const getSummary = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const claimAddressUrlParam = urlSearchParams.get("claimAddress");

    const claimAddress = Web3.utils.isAddress(claimAddressUrlParam)
      ? claimAddressUrlParam
      : address;

    setClaimAddress(claimAddress);

    window.gtag("event", "click", {
      type: "ape in",
      status: "pending",
    });

    return axios
      .get("summary/2021", {
        params: {
          // "0xf303075F90f2Be4D07F815789609254a4880D70d"
          // "0x62C9E40fa67A70fC6661e1f5a878f203bf1c7125"
          // "0x694Ea1A40Ec76d3A6CF5B75480a748397e1A7846"
          // "0x27384750b547882AC12f1688E1f51774d737F5E5"
          // "0x90c19feA1eF7BEBA9274217431F148094795B074"
          // "0x9f0083396a650234C95fd33472c612C01373511e",
          address: claimAddress,
        },
      })
      .then((result) => {
        console.log("result: ", result);
        setSummary(result);

        window.gtag('event', 'click', {
          'type': 'ape in',
          'status': 'success'
        });
      });
  };

  return (
    <div className={style.app}>
      <Header address={address} />
      <main className={style.main}>
        {summary ? (
          <ScoreBox
            wallet={wallet}
            address={address}
            claimAddress={claimAddress}
            summary={summary}
          />
        ) : (
          <ApeBox
            onApe={getSummary}
            isConnected={isConnected}
            connectWallet={connectWallet}
          />
        )}
      </main>
    </div>
  );
}

export default App;
