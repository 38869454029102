import style from "./index.module.scss";
import topBlockie from "../../assets/img/top-blockie.png";
import downBlockie from "../../assets/img/down-blockie.png";

const Box = (props) => {
  return (
    <div className={style.mainBox}>
      <img className={style.downBlockie} src={downBlockie} alt="" />
      <div className={style.boxContent}>{props.children}</div>
      <img className={style.topBlockie} src={topBlockie} alt="" />
    </div>
  );
};

export default Box;
