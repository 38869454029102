import { useEffect, useState, useCallback } from "react";
import style from "./index.module.scss";
import Box from "../Box";
import Button from "../Button";
import creatureImgMap from "./creatureImgMap";
import twitter from "../../assets/img/twitter.png";
import discord from "../../assets/img/discord.png";
import Web3 from "web3";
import claimAbi from "../../util/claimAbi";
import { Modal } from "antd";
import CONST from "../../const";

const isValidArray = (array) => Array.isArray(array) && array.length > 0;

const ScoreBox = (props) => {
  const {
    score = 0,
    creature = "",
    details = [],
    index,
    proof = [],
    can_claim = 0,
  } = props.summary || {};

  const [imagePath, setImagePath] = useState("");
  const [isClaiming, setIsClaiming] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [claimContractInstance, setClaimContractInstance] = useState(null);
  const [canClaim, setCanClaim] = useState(can_claim);
  const [claimedCount, setClaimedCount] = useState(Infinity);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: 'Claim NFT',
      send_to: 'G-Q7XNLB2V8D'
    })
  }, [])

  useEffect(() => {
    if (props.wallet && !web3) {
      const web3 = new Web3(props.wallet.provider);
      setWeb3(web3);
    }
  }, [props.wallet, web3]);

  useEffect(() => {
    if (web3) {
      const claimContractIns = new web3.eth.Contract(
        claimAbi,
        CONST.CONTRACT_ADDRESS
      );
      setClaimContractInstance(claimContractIns);
    }
  }, [web3]);

  useEffect(() => {
    const isClaimedFunc = () => {
      claimContractInstance.methods
        .isClaimed(index)
        .call()
        .then((result) => {
          setIsClaimed(result);
          console.log("isClaimed: ", result);
        })
        .catch((e) => {
          setIsClaimed(false);
          console.error(e);
        });
    };

    if (claimContractInstance && index >= 0) {
      isClaimedFunc();
    }
  }, [index, claimContractInstance]);

  useEffect(() => {
    const getMaxCanClaim = () => {
      claimContractInstance.methods
        .count()
        .call()
        .then((claimedCount) => {
          setClaimedCount(claimedCount);
          console.log("claimed count: ", claimedCount);
        })
        .catch((e) => {
          console.error(e);
        });
    };

    if (claimContractInstance) {
      getMaxCanClaim();
    }
  }, [claimContractInstance]);

  useEffect(() => {
    setCanClaim(can_claim && Number(claimedCount) < CONST.MAX_CLAIM_COUNT);
  }, [can_claim, claimedCount]);

  const getLevel = (score) => {
    if (score < 1000) return "bronze";
    if (score >= 1000 && score < 2000) return "silver";
    if (score >= 2000) return "gold";
  };

  useEffect(() => {
    if (score && creature) {
      const level = getLevel(Number(score));
      const imgMapKey = `${level}/${creature.toLocaleLowerCase()}`;

      if (creatureImgMap[imgMapKey]) {
        creatureImgMap[imgMapKey]()
          .then((imagePath) => {
            setImagePath(imagePath);
          })
          .catch(console.error);
      }
    }
  }, [score, creature]);

  const claim = useCallback(async () => {
    window.gtag("event", "click", {
      type: "claim nft",
      status: 'pending',
    });

    if (index >= 0 && isValidArray(proof)) {
      return claimContractInstance.methods
        .claim(index, props.claimAddress, score, creature, proof)
        .send({
          from: props.address,
        })
        .then((result) => {
          console.log("claim success: ", result);

          const txUrl = `${CONST.EXPLORER_TX_PREFIX}${result.transactionHash}`;
          Modal.success({
            title: "Claim Success",
            content: (
              <a href={txUrl} target="_blank" rel="noopener noreferrer">
                View on block explorer
              </a>
            ),
          });

          setIsClaimed(true);

          window.gtag("event", "click", {
            type: "claim",
            status: "success",
          });
        })
        .catch((e) => {
          console.error("claim error: ", e);
          Modal.error({
            title: "Claim Failed",
            content: e.message,
            width: 560,
          });

          window.gtag("event", "click", {
            type: "claim",
            status: "failed",
          });
        });
    }
  }, [
    index,
    proof,
    props.address,
    props.claimAddress,
    score,
    creature,
    claimContractInstance,
  ]);

  return (
    <Box>
      <div className={style.titleContainer}>
        <div className={style.scoreWrapperTitle}>Your 2021 APE score</div>
        <div className={style.creatureWrapperTitle}>
          Congrats on creating this creature
        </div>
        <div className={style.scoreWrapperScore}>{parseInt(Number(score))}</div>
        <div className={style.creatureWrapperCreature}>{creature}</div>
      </div>
      {imagePath ? (
        <div className={style.nftContainer}>
          <img src={imagePath} alt="creature" />
        </div>
      ) : (
        <></>
      )}

      <div className={style.scoreDetailContainer}>
        {Array.isArray(details) && details.length > 0 ? (
          details.map((item, index) => {
            return (
              <div key={index} className={style.detailItem}>
                <div className={style.detail}>
                  <div className={style.detailTitle}>{item.title}</div>
                  {item.reason ? (
                    <div className={style.detailReason}>{item.reason}</div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={style.detailScore}>
                  +{parseInt(Number(item.score))}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>

      <div className={style.buttonContainer}>
        {isClaimed ? (
          <Button className={style.button} disabled={isClaimed}>
            Claimed
          </Button>
        ) : (
          // <Button
          //   className={style.button}
          //   disabled={!canClaim}
          //   loading={isClaiming}
          //   onClick={async () => {
          //     setIsClaiming(true);
          //     await claim();
          //     setIsClaiming(false);
          //   }}
          // >
          //   {isClaiming ? "Claiming" : "Claim Your NFT"}
          // </Button>
          <Button className={style.button} disabled={true}>
            10k limit reached
          </Button>
        )}
        {!canClaim ? (
          claimedCount >= CONST.MAX_CLAIM_COUNT ? (
            <div className={style.notClaimReason}>
              All NFTs have been claimed.
            </div>
          ) : (
            <div className={style.notClaimReason}>
              Your APEscore is too low to claim.
            </div>
          )
        ) : (
          <></>
        )}
      </div>

      <div className={style.specificationContainer}>
        <div>
          The NFT is presented to you by{" "}
          <a
            style={{ color: "#2539F4" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://metacraft.cc/"
          >
            Metacraft
          </a>
        </div>
        <div>
          it's Metacraft alpha season pass, and more scenarios will be expanded in future {" "}
          <a
            style={{ color: "#2539F4" }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.metacraft.cc/ape-score-2021/what-is-ape-score"
          >
            more info
          </a>
        </div>
        <div>
          Metacraft is still under development, welcome to join the community for latest news.
        </div>
      </div>

      <div className={style.contactContainer}>
        Join community
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/MetaCraftCC"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.com/invite/PvzFHa4QJd"
        >
          <img src={discord} alt="discord" />
        </a>
      </div>
    </Box>
  );
};

export default ScoreBox;
